var isOnline = (navigator.onLine) ? true : false;

/* API Env */
var isProdEnv = true;

var apiBaseUrl =  (isProdEnv) ?
'https://mobyads.in/moby/v4/' : 
'https://mobyads.in/preprod-moby/v4/';

var fsBrandName = 'Moby';

const config = {
    'isProd':isProdEnv,
    'appStorageVersion':'1.0',

    'isMiniAppWebView':false,
    'spashSliderMSecond':5000,

    'quizTimeLimit':30,
    'mapZoom':15,
    'API_URL':apiBaseUrl,
    'BASE_URL':'https://mobyads.in/',
    'IMAGE_URL':'https://mobyads.in/moby/',
    'trackingId':'G-HMXJV455DD',
    
    'appName':'Cashback',
    'mapAPIKey':'AIzaSyCTj1Qvvri4GqRk-orc4Edtp1E99jFCeik',
    // 'mobilePrefix':'+91 ',
    'mobilePrefix':'+',
    'isOnline':isOnline,
    'spashScreenMessage':'Search Any Brand<br/>Click Our Coupons<br/>Get Highest Cashback',

    'adsense':{
        'clientId':'ca-pub-4951273399919690',
        'slotId':'4500290973'
    },
    
    'loginLabel':{
        'buttonName':'GET THE CODE',
        'OTPMessage':'You will receive OTP On this <br/>number only.',
        'OTPConfirm':'We have sent you SMS with code to number',
        'VeriyButton':'Verify Code',
        'mobileValid':'Mobile must be valid.',
        'otpValid':'OTP Verification Failed.',
        'verifyScreenTitle':fsBrandName+' Gives Real Cash !',
        'verifyScreenSubTitle':'Use correct info Bellow to match your Bank<br/>Ac, Else online wallet may not credit.'
    },
    'updateProfile':{
        'donateLabel':'Would you like to DONATE your '+fsBrandName+' earning to charity (NGO/Alumani/Others)?',
        'acceptTerms':'I agree to share my profile '+fsBrandName+' app with '+fsBrandName+' physical media pvt/ltd.'
    },
    'pageAnimation':{
        initial: { opacity: 0, x: 0 },
        in: { opacity: 1,  x: 0 },
        out: { opacity: 0, x: "100vw" }
    },
    'existingUserConnected' : function(responseJson){
        if(responseJson.fbIsUserExist===true){
            if(responseJson.fsUserContactNo!==localStorage.getItem('fsDeviceId')){
                if(localStorage.getItem('OpenPWAUserPromo')==='FUTUREPAY'){
                    localStorage.setItem('fbIsMobileConnected','true');
                }
                localStorage.setItem('userPhone',responseJson.fsUserContactNo);
            }else{
                localStorage.setItem('fbIsMobileConnected','false');
                localStorage.removeItem('userPhone');
            }
        }else{
            localStorage.setItem('fbIsMobileConnected','false');
            localStorage.removeItem('userPhone');
        }
    },
    'storeDeviceInfo':function(fpPromise){
        var asyncFunct = new Promise(function(resolve, reject) {
            if(localStorage.getItem('fsDeviceId')===null){
                fpPromise
                .then(fp => fp.get())
                .then(result => {
                    /* Issue when same model of device */
                    var newVisitorId = result.visitorId.substring(0, 18) +'-'+ Date.now();
                    localStorage.setItem('fsDeviceId',newVisitorId);
                    resolve(newVisitorId);
                })
            }else{
                resolve(localStorage.getItem('fsDeviceId'));
            }
        });
        return asyncFunct;
    },
    'fetchInformation':function(formBody){
        var asyncFunct = new Promise(function(resolve, reject) {

            var requestHeader = new Headers();
            var foDeviceDetails = localStorage.getItem('foDeviceDetails');
            if(foDeviceDetails!==null){
                requestHeader.append("Device-Details", foDeviceDetails);
            }

            var foGlobalSetting = localStorage.getItem('foGlobalSetting');
            if(foGlobalSetting!==null){
                if(JSON.parse(foGlobalSetting).fsToken!==undefined){
                    formBody.fsToken = JSON.parse(foGlobalSetting).fsToken;
                }
            }

            if(foGlobalSetting!==null){
                formBody.fiUserId = JSON.parse(foGlobalSetting).fiUserId;
            }

            if(localStorage.getItem('userPhone')!=="" && localStorage.getItem('userPhone')!==null){
                var PhonePrefix = "";
                if(localStorage.getItem('PhonePrefix')!==null){
                    PhonePrefix = localStorage.getItem('PhonePrefix');
                    if(PhonePrefix==="+"){
                        PhonePrefix = "";
                    }
                }
                formBody.fsUserContact = localStorage.getItem('userPhone');
            }

            if(localStorage.getItem('fsDeviceId')!=="" && localStorage.getItem('fsDeviceId')!==null ){
                formBody.fsDeviceId= localStorage.getItem('fsDeviceId');
            }
            if(localStorage.getItem('OpenPWAUserPromo')!=="" && localStorage.getItem('OpenPWAUserPromo')!==null ){
                formBody.OpenPWAUserPromo=localStorage.getItem('OpenPWAUserPromo');
            }
            formBody.isPWA = 'yes';
            fetch(config.API_URL, { method: 'POST','headers':requestHeader,body:JSON.stringify(formBody) })
            .then((response) => response.text())
            .then((responseText) => {
                resolve(JSON.parse(responseText));
            })
        })
        return asyncFunct;
    },
    'pageTransition': {  type: "tween", ease: "anticipate", duration: 0.1 },
    'getDistanceFromLatLonInKm':function(lat1,lon1,lat2,lon2){
        var R = 6371; // Radius of the earth in km
        var dLat = config.deg2rad(lat2-lat1);  // deg2rad below
        var dLon = config.deg2rad(lon2-lon1); 
        var a = 
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(config.deg2rad(lat1)) * Math.cos(config.deg2rad(lat2)) * 
            Math.sin(dLon/2) * Math.sin(dLon/2)
            ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
    },
    deg2rad: function(deg){
        return deg * (Math.PI/180);
    },
    getImageUrl: function(url){
        return config.IMAGE_URL+url;
    },
    getTextFirstLink(text){
        if(text===""){ return ""; }
        var matches = text.match(/\bhttps?:\/\/\S+/gi);
        if(matches!==null && matches.length > 0){
            return matches[0]
        }else{
            return "";
        }
    },
    fatchAppStaticLabels(){
        // var formBody = {fsAction:'getAllStaticLabels','fsDeviceId':'pwa-device'};
        // config.fetchInformation(formBody).then((responseJson)=>{
        //     localStorage.setItem('AppLabels',JSON.stringify(responseJson));
        // });
    },
    updateUserSession(eventType,fiAdId=0){
        var requestParams = {
            "fsEventType": eventType,
            "fsAction":"updateUserSession",
            "fiAdId":fiAdId
        };
        config.fetchInformation(requestParams).then((res)=>{
            // console.log(res);
        })
    },
    // getDynamicLabel(screenName,MessageSlug){
    //     var allLabels = localStorage.getItem('AppLabels');
    //     allLabels = JSON.parse(allLabels);
    //     console.log(allLabels);
    //     return allLabels[screenName][MessageSlug];
    // },
    formatDate(parseDate) {
        parseDate = parseDate.split(', ');
        var convertDate = parseDate[0].split("-").reverse().join("-");
        var date = new Date(convertDate);
        var monthArray = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sep','Oct','Nov','Dec'];
        var dateDetails = date.getDate();
        if(dateDetails===1){
            dateDetails=dateDetails+'st';
        }else if(dateDetails===2){
            dateDetails=dateDetails+'nd';
        }else{
            dateDetails=dateDetails+'th';
        }
        return dateDetails + " " + monthArray[date.getMonth()] + " " + date.getFullYear() + " " + parseDate[1];
    },
    getPersontageValue(total,amount){
        return (100*amount)/total;
    },
    getRequestHeaders(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", localStorage.getItem('AuthToken'));
        if(config.isProd){
            myHeaders.append("Access-Control-Allow-Origin", 'http://app.mobyads.in');
        }else{
            myHeaders.append("Access-Control-Allow-Origin", 'http://localhost:3000');
        }
        myHeaders.append("Access-Control-Allow-Methods", 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
        myHeaders.append("Connection", 'Keep-Alive');
        myHeaders.append("Content-Encoding", 'gzip');
        myHeaders.append("cache-control", 'no-cache');
        return { 
            'method': 'GET',
            'headers': myHeaders
        };
    },
    getProviderNameFromUrl : function(){
        config.setPWAColors();
        return config.getProviderDataFromUrl(false);
    },
    getProviderDataFromUrl: function(fbNeedUserInfo=true){
        const userInfo = {};
        var currentUrl = window.location.href;
        var spiltArray = currentUrl.split('/publisher/');
        var ProviderName="";
        if(spiltArray[1]!==undefined){
            var providerInfo = spiltArray[1].split('/?');
            if(providerInfo[1]===undefined){
                providerInfo = spiltArray[1].split('?');
            }
            if(fbNeedUserInfo){
                userInfo.fsProviderName = providerInfo[0];
                if(providerInfo[1]!==undefined && providerInfo[1]!==""){
                    /* Ignore Storage for Demo Promo */
                    if(providerInfo[0]!=="A011"){
                        localStorage.setItem('OpenPWAUserPromo',userInfo.fsProviderName);
                    }
        
                    const rawParams = providerInfo[1].replace("?", "").split("&");
                    rawParams.forEach((item) => {
                        if(item.includes('fsMobile=')){
                            item = item.split("fsMobile=");
                            userInfo['fsMobile'] = item[1];
                        }else{    
                            item = item.split("=");
                            userInfo[item[0]] = item[1];
                        }
                    });
                    
                    Object.keys(userInfo).forEach((key)=> {
                        if(userInfo[key]!==undefined && userInfo[key]!==""){
                            localStorage.setItem(key,userInfo[key]);
                        }
                    });
                    if(userInfo.fsMobile!==undefined && userInfo.fsMobile!==""){
                        var fsMobile = userInfo.fsMobile;
                        // fsMobile = fsMobile.replace("+","");
                        localStorage.setItem('userPhone',fsMobile);
                    }
                    userInfo.fiDeviceType = localStorage.getItem('fiDeviceType');
                    return userInfo;
                }
            }
            ProviderName = providerInfo[0];   
        }
        return (ProviderName!=="") ? ProviderName : "";
    },
    getCashbackString : function(fbIsMyCash){
        var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
        return fbIsMyCash==='yes'?foGlobalSetting.fsMyCashLabel:'Cashback';
    },
    getGlobalCashbackString : function(){
        var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
        return foGlobalSetting.fsMyCashLabel;
    },
    replaceMyCash : function(string){
        var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
        return string.replace('MyCash',foGlobalSetting.fsMyCashLabel);
    },
    getMyCashPer : function(){
        var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
        return foGlobalSetting.fsMyCashPer;
    },
    convertLinkToRealLink : function(text) {  
        // Put the URL to variable $1 after visiting the URL
        var Rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;

        // Replace the RegExp content by HTML element
        return text.replace(Rexp,"<a href='$1' target='_blank'>$1</a>");
    },
    getURLReferral : function(){
        var currentUrl = window.location.href;
        var returnReferralCode = "OTHER";
        var faProviders = {
            'cashbuddy':'CASHBUDDY',
            'm91shopping':'M91APP',
            'mpokket':'MPOKKET',
            'emtrip':'EMTRIP',
            'midday-shopping':'MIDDAY',
            'fatakpay':'FATAKP',
            'cbazar-shop':'CBAZAR',
            'fitpass':'FITPASS',
            'everacabs':'EVERA',
            'yesmadam':'YESMADAM',
            'app.moby':'SELECTION',
            'pwa-staging':'ORGANIC',
        };

        if(currentUrl.includes('localhost')){
            returnReferralCode = localStorage.getItem('OpenPWAUserPromo') ?? 'SELECTION';
        }else{
            Object.keys(faProviders).forEach((key)=> {
                if(currentUrl.includes(key)){
                    return returnReferralCode = faProviders[key];
                }
            });
        }

        var fsProvider = config.getProviderDataFromUrl(false); // fbNeedUserInfo = false
        returnReferralCode = fsProvider!=="" ? fsProvider : returnReferralCode;

        localStorage.setItem('OpenPWAUserPromo',returnReferralCode);
        return returnReferralCode;
    },
    getProviderSettingOptions : function(fsReferrerCode){
        var fsImgURL = config.IMAGE_URL + 'images/pwa-images/';
        var foSetting = {
            fbIsDirectRegistration : true,
            fbIsInputDisable : false,
            fbIsProvideMobile: true,
            fbIsPWAOpenOnWeb:false,
            fiUserWallet: 1,
            fsMainPColor:"#F16521",
            fsRedColor:"#E84855",
            fsOrgangeColor:"#FEC108",
            fsGreenColor:"#1FA48E",
            fsYellowColor:"#eaea35",
            fsBlueColor:"#6B95C7",
            fsLightBlack:"#BFC1C1",
            fsDarkBlack:"#808384",
            fsBlack:"#2C2C2C",
            fsBodyBg:"#EFEFEF",
            fsWhite:"#fff",
            fsMetaTitle:'Moby Cashback',
            fsMetaDesc:'Moby Cashback',
            fsIcon:fsImgURL+'logo/logo.png',
            fsLogo:fsImgURL+'logo/logo.png',
            fsSplashImage:fsImgURL+'other/gurugram.PNG',
            fsLazyLoad:fsImgURL+'other/lazy-load.png',
            fsNoData:fsImgURL+'other/no_datafound.png',
            fsNoInternet:fsImgURL+'other/no-internet.png',
            fsQRImg:fsImgURL+'other/qr-sample.png',
            fsTapIcon:fsImgURL+'other/tap-icon.png',
            fsYouTube:fsImgURL+'other/youtube-sample.png',
            fsMyCashExpiringImage:fsImgURL+'other/mycash-expiring.png',
            fsLoader:fsImgURL+'loader/loader.gif',
            fsCouponImg:fsImgURL+'coupon/coupon-organic.png',
            fsDinoutImg:fsImgURL+'other/dineout-checkout.jpeg'
        }
        if(fsReferrerCode==="YESMADAM"){
            foSetting.fbIsProvideMobile =  false;
            foSetting.fiUserWallet = 10;
            foSetting.fsMainPColor = "#b93c5b";
            foSetting.fsRedColor = "#E84855";
            foSetting.fsOrgangeColor = "#FEC108";
            foSetting.fsGreenColor = "#b93c5b";
            foSetting.fsYellowColor = "#eaea35";
            foSetting.fsBlueColor = "#215492";
            foSetting.fsLightBlack = "#BFC1C1";
            foSetting.fsDarkBlack = "#808384";
            foSetting.fsBlack = "#2C2C2C";
            foSetting.fsBodyBg = "#EFEFEF";
            foSetting.fsWhite = "#fff";
            foSetting.fsLogo = fsImgURL+'logo/yesmadam.png';
            foSetting.fsCouponImg = fsImgURL+'coupon/coupon-yesmadam.png';
        }
        if(fsReferrerCode==="EVERA"){
            foSetting.fbIsProvideMobile =  false;
            foSetting.fbIsPWAOpenOnWeb = true;
            foSetting.fiUserWallet = 9;
            foSetting.fsMainPColor = "#214b85";
            foSetting.fsRedColor = "#E84855";
            foSetting.fsOrgangeColor = "#FEC108";
            foSetting.fsGreenColor = "#214b85";
            foSetting.fsYellowColor = "#eaea35";
            foSetting.fsBlueColor = "#215492";
            foSetting.fsLightBlack = "#BFC1C1";
            foSetting.fsDarkBlack = "#808384";
            foSetting.fsBlack = "#2C2C2C";
            foSetting.fsBodyBg = "#EFEFEF";
            foSetting.fsWhite = "#fff";
            foSetting.fsLogo = fsImgURL+'logo/everacab.png';
            foSetting.fsCouponImg = fsImgURL+'coupon/coupon-everacab.png';
        }
        if(fsReferrerCode==="FITPASS"){
            foSetting.fbIsProvideMobile =  false;
            foSetting.fiUserWallet = 8;
            foSetting.fsMainPColor = "#35cf7a";
            foSetting.fsRedColor = "#E84855";
            foSetting.fsOrgangeColor = "#FEC108";
            foSetting.fsGreenColor = "#f4344c";
            foSetting.fsYellowColor = "#eaea35";
            foSetting.fsBlueColor = "#0b4eb1";
            foSetting.fsLightBlack = "#829ab1";
            foSetting.fsDarkBlack = "#627594";
            foSetting.fsBlack = "#2C2C2C";
            foSetting.fsBodyBg = "#EFEFEF";
            foSetting.fsWhite = "#fff";
            foSetting.fsLogo = fsImgURL+'logo/fitpass.png';
            foSetting.fsCouponImg = fsImgURL+'coupon/coupon-fitpass.png';
        }
        if(fsReferrerCode==="M91APP"){
            foSetting.fbIsProvideMobile =  false;
            foSetting.fiUserWallet = 5;
            foSetting.fsMainPColor = "#251f39";
            foSetting.fsRedColor = "#E84855";
            foSetting.fsOrgangeColor = "#d6a835";
            foSetting.fsGreenColor = "#251f39";
            foSetting.fsYellowColor = "#d6a835";
            foSetting.fsBlueColor = "#251f39";
            foSetting.fsLightBlack = "#BFC1C1";
            foSetting.fsDarkBlack = "#808384";
            foSetting.fsBlack = "#2C2C2C";
            foSetting.fsBodyBg = "#EFEFEF";
            foSetting.fsWhite = "#fff";
            foSetting.fsLogo = fsImgURL+'logo/m91.png';
            foSetting.fsCouponImg = fsImgURL+'coupon/coupon-m91.png';
        }
        if(fsReferrerCode==="CASHBUDDY"){
            foSetting.fbIsProvideMobile =  false;
            foSetting.fiUserWallet = 6;
            foSetting.fsMainPColor = "#008ddf";
            foSetting.fsRedColor = "#E84855";
            foSetting.fsOrgangeColor = "#FEC108";
            foSetting.fsGreenColor = "#008ddf";
            foSetting.fsYellowColor = "#eaea35";
            foSetting.fsBlueColor = "#215492";
            foSetting.fsLightBlack = "#BFC1C1";
            foSetting.fsDarkBlack = "#808384";
            foSetting.fsBlack = "#2C2C2C";
            foSetting.fsBodyBg = "#EFEFEF";
            foSetting.fsWhite = "#fff";
            foSetting.fsCouponImg = fsImgURL+'coupon/coupon-cashbuddy.png';
        }
        if(fsReferrerCode==="EMTRIP"){
            foSetting.fbIsProvideMobile =  false;
            foSetting.fiUserWallet = 7;
            foSetting.fsMainPColor = "#008ddf";
            foSetting.fsRedColor = "#E84855";
            foSetting.fsOrgangeColor = "#FEC108";
            foSetting.fsGreenColor = "#008ddf";
            foSetting.fsYellowColor = "#eaea35";
            foSetting.fsBlueColor = "#215492";
            foSetting.fsLightBlack = "#BFC1C1";
            foSetting.fsDarkBlack = "#808384";
            foSetting.fsBlack = "#2C2C2C";
            foSetting.fsBodyBg = "#EFEFEF";
            foSetting.fsWhite = "#fff";
            foSetting.fsCouponImg = fsImgURL+'coupon/coupon-emt.png';
        }
        if(fsReferrerCode==="MIDDAY"){
            foSetting.fbIsDirectRegistration  = false;
            foSetting.fbIsProvideMobile = true;
            foSetting.fbIsPWAOpenOnWeb = true;
            foSetting.fsMainPColor = "#005aab";
            foSetting.fsRedColor = "#ee1a24";
            foSetting.fsOrgangeColor = "#FEC108";
            foSetting.fsGreenColor = "#005aab";
            foSetting.fsYellowColor = "#eaea35";
            foSetting.fsBlueColor = "#005aab";
            foSetting.fsLightBlack = "#BFC1C1";
            foSetting.fsDarkBlack = "#808384";
            foSetting.fsBlack = "#2C2C2C";
            foSetting.fsBodyBg = "#EFEFEF";
            foSetting.fsWhite = "#fff";
            foSetting.fsMetaTitle = 'Midday Shopping';
            foSetting.fsMetaDesc = 'Midday Shopping';
            foSetting.fsIcon = fsImgURL+'logo/midday.png';
            foSetting.fsLogo = fsImgURL+'logo/midday.png';
            foSetting.fsCouponImg = fsImgURL+'coupon/coupon-midday.png';
        }
        if(fsReferrerCode==="ORGANIC" || fsReferrerCode==="MOBYTRIAL"  || fsReferrerCode==="GOMMT" || 
        fsReferrerCode==="PBCASH5000" || fsReferrerCode==="HEROCASH" || fsReferrerCode==="SELECTION" || 
        fsReferrerCode==="TIMBRECASH"){
            foSetting.fbIsPWAOpenOnWeb = true;
        }   
        if(fsReferrerCode==="MPOKKET"){
            foSetting.fbIsDirectRegistration  = false;
            foSetting.fbIsInputDisable = true;
            foSetting.fsMainPColor = "#008ddf";
            foSetting.fsRedColor = "#E84855";
            foSetting.fsOrgangeColor = "#FEC108";
            foSetting.fsGreenColor = "#008ddf";
            foSetting.fsYellowColor = "#eaea35";
            foSetting.fsBlueColor = "#215492";
            foSetting.fsLightBlack = "#BFC1C1";
            foSetting.fsDarkBlack = "#808384";
            foSetting.fsBlack = "#2C2C2C";
            foSetting.fsBodyBg = "#EFEFEF";
            foSetting.fsWhite = "#fff";
            foSetting.fsLogo = fsImgURL+'logo/mpokket.png';
            foSetting.fsLoader = fsImgURL+'loader/mpokket-loader.gif';
            foSetting.fsCouponImg = fsImgURL+'coupon/coupon-mpokket.png';
        }
        if(fsReferrerCode==="FATAKP"){
            foSetting.fbIsDirectRegistration  = false;
            foSetting.fbIsInputDisable = true;
            foSetting.fsMainPColor = "#44226E";
            foSetting.fsRedColor = "#F74D2E";
            foSetting.fsOrgangeColor = "#FEC108";
            foSetting.fsGreenColor = "#44226E";
            foSetting.fsYellowColor = "#FEC036";
            foSetting.fsBlueColor = "#215492";
            foSetting.fsLightBlack = "#BFC1C1";
            foSetting.fsDarkBlack = "#808384";
            foSetting.fsBlack = "#2C2C2C";
            foSetting.fsBodyBg = "#EFEFEF";
            foSetting.fsWhite = "#fff";
            foSetting.fsLogo = fsImgURL+'logo/fatakpay.png';
            foSetting.fsCouponImg = fsImgURL+'coupon/coupon-fatakpay.png';
        }
        if(fsReferrerCode==="CBAZAR"){
            foSetting.fbIsDirectRegistration  = true;
            foSetting.fbIsInputDisable = true;
            foSetting.fsMainPColor = "#000000";
            foSetting.fsRedColor = "#F74D2E";
            foSetting.fsOrgangeColor = "#FEC108";
            foSetting.fsGreenColor = "#1FA48E";
            foSetting.fsYellowColor = "#fdb813";
            foSetting.fsBlueColor = "#215492";
            foSetting.fsLightBlack = "#BFC1C1";
            foSetting.fsDarkBlack = "#808384";
            foSetting.fsBlack = "#2C2C2C";
            foSetting.fsBodyBg = "#EFEFEF";
            foSetting.fsWhite = "#fff";
            foSetting.fsLogo = fsImgURL+'logo/cabbazar.png';
            foSetting.fsCouponImg = fsImgURL+'coupon/coupon-cabbazzar.png';
        }
        return foSetting;
    },
    setPWAColors : function(){
        var fsReferrerCode = config.getURLReferral();
        var foSetting = config.getProviderSettingOptions(fsReferrerCode);
        document.body.classList.add(fsReferrerCode);
        localStorage.setItem('fbIsDirectRegistration',foSetting.fbIsDirectRegistration);
        document.documentElement.style.setProperty('--main-primary-color', foSetting.fsMainPColor);
        document.documentElement.style.setProperty('--red-color', foSetting.fsRedColor);
        document.documentElement.style.setProperty('--organge-color', foSetting.fsOrgangeColor);
        document.documentElement.style.setProperty('--green-color', foSetting.fsGreenColor);
        document.documentElement.style.setProperty('--yellow-color', foSetting.fsYellowColor);
        document.documentElement.style.setProperty('--blue-color', foSetting.fsBlueColor);
        document.documentElement.style.setProperty('--light-black', foSetting.fsLightBlack);
        document.documentElement.style.setProperty('--dark-black', foSetting.fsDarkBlack);
        document.documentElement.style.setProperty('--black', foSetting.fsBlack);
        document.documentElement.style.setProperty('--body-bg', foSetting.fsBodyBg);
        document.documentElement.style.setProperty('--white', foSetting.fsWhite);
        document.querySelector("link[rel~='icon']").href = foSetting.fsIcon;
        document.querySelector("title").innerHTML = foSetting.fsMetaTitle;
        document.querySelector("meta[name~=apple-mobile-web-app-title]").content = foSetting.fsMetaTitle;
        document.querySelector("meta[name~=description]").content = foSetting.fsMetaDesc;
    },
    getProviderReferral : function(){
        var fsReferrerCode = "OTHER";
        var temp = localStorage.getItem('OpenPWAUserPromo');
        if(temp!==undefined && temp!==null){
            fsReferrerCode = temp;
        }

        return fsReferrerCode;
    },
    getProviderSetting : function(){
        var fsReferralCode = localStorage.getItem('OpenPWAUserPromo');
        let configDetails = config.getProviderSettingOptions(fsReferralCode);

        let fsReferFrom = localStorage.getItem('fsReferFrom');
        if(fsReferFrom!=undefined && fsReferFrom!==null){
            configDetails.fsLogo = config.IMAGE_URL + 'images/pwa-images/logo/'+fsReferFrom+'.png';
            configDetails.fbIsPWAOpenOnWeb = true;
        }
        return configDetails;
    },
    isReferreFromExist : function(){
        let fsReferFrom = localStorage.getItem('fsReferFrom');
        if(fsReferFrom!=undefined && fsReferFrom!==null){
            return true;
        }
        return false;
    }
};

export default config;