import React from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function BrowserRestrictedPopup(props) {
    const userAgent = window.navigator.userAgent;

    let popupContent = "<p>We can't load the payment options as popups are blocked. Please open your browser settings to unblock popups.<br/></p>";

    if (userAgent.includes('Chrome')) {
        popupContent+= "<p><b>Chrome (Android): Go to Settings > Site settings > Pop-ups and redirects and allow popups..</b></p>";
    } else if (userAgent.includes('Safari')) {
        popupContent+= "<p><b>Safari (iOS): Go to Settings > Safari > Block Pop-ups and toggle off the setting.</b></p>";
    }

    return (
        <Dialog
          classes={{root:'popupMaxWidth'}}
          open={true}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="mobyPopup continuePopupDesign">
            <DialogTitle id="alert-dialog-slide-title">Popup disabled!</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" dangerouslySetInnerHTML={{__html: popupContent}}>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={props.handleClose} variant="contained" color="primary">
                  Ok
                </Button>
              </DialogActions>
          </div>
        </Dialog>
    );
}